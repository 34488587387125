<template>
	<div>
		<div @click="onImageClick">
			<div v-if="filename">
				<img class="rounded"
					v-if="base64"
					:alt="alt"
					:id="img_id"
					:src="filename"
				/>
				<OfflineMedia
					v-else
					:filename="filename"
					:src.sync="photoSrc"
					customClass="rounded"
				/>
			</div>

			<img
				class="rounded"
				v-else
				:id="img_id"
				:src="require(`GroomyRoot/assets/img/horse_placeholder2.jpg`)"
				:alt="alt"
			/>
		</div>

		<e-fancybox
			ref="fancybox"
			:show-light-box="false"
			:images="[{
				thumb: photoSrc,
				src: photoSrc
			}]"
		></e-fancybox>
	</div>
</template>

<script type="text/javascript">
    import Documents from "@/mixins/Documents.js";

	export default {
		mixins: [Documents],
		components: {
			OfflineMedia: () => import('GroomyRoot/components/Utils/OfflineMedia')
		},
		props: {
            img_id: { type: String },
			filename: { type: String },
			base64: { type: Boolean, default: false },
			alt: { type: String, default: 'Photo cheval' },
			type: {type: String, default: 'largeScreen'}
		},
		data: () => ({
			photoSrc: null
		}),
		methods:{
			onImageClick () {
				if(this.type == "smallScreen"){
					if (this.photoSrc) {
						this.openFancybox()
					} else {
						this.$emit('click')
					}
				} else {
					if( this.photoSrc){
						this.$emit('openModal')
					} else {
						this.$emit('click')
					}
				}
			},
			openFancybox () {
				this.$refs.fancybox.showImage(0)
			},

			// Android only
			onPermAccepted() {
				this.$emit('click')
			}
		}
	}
</script>
